import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header" }

export function render(_ctx, _cache) {
  const _component_el_image = _resolveComponent("el-image")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _component_QuestionFilled = _resolveComponent("QuestionFilled")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_affix = _resolveComponent("el-affix")

  return (_openBlock(), _createBlock(_component_el_affix, { offset: 0 }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              class: "col-logo",
              span: 1
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "logo",
                  onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onLogoClick && _ctx.onLogoClick(...args)))
                }, [
                  _createVNode(_component_el_image, { src: "/img/ddg-logo-128.png" })
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass('logo-line' + ((_ctx.windowWidth < 768) ? '-mobile' : ''))
                }, _cache[2] || (_cache[2] = [
                  _createTextVNode(" MC.health"),
                  _createElementVNode("br", null, null, -1),
                  _createTextVNode("Prüfungsplattform ")
                ]), 2)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.showHelp)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "col-help",
              onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.onHelpClick && _ctx.onHelpClick(...args)))
            }, [
              _createVNode(_component_el_icon, { size: "48px" }, {
                default: _withCtx(() => [
                  _createVNode(_component_QuestionFilled)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}