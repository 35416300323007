import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "question-list" }
const _hoisted_2 = {
  key: 0,
  class: "question-buttons"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "selection-body-mobile" }

export function render(_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.windowWidth >= 892)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                return (_openBlock(), _createBlock(_component_el_col, { span: 0.1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      class: _normalizeClass(item.questionid == _ctx.questionid ? 'highlight' : (item.isanswered ? 'answered' : 'unanswered')),
                      size: "small",
                      circle: "",
                      onClick: $event => (_ctx.onQuestionClick(item.questionid))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(index+1), 1)
                      ]),
                      _: 2
                    }, 1032, ["class", "onClick"])
                  ]),
                  _: 2
                }, 1024))
              }), 256))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.windowWidth < 892)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "selection-header" }, " Fragenliste ", -1)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                  return (_openBlock(), _createBlock(_component_el_col, { span: 0.1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        class: _normalizeClass(item.questionid == _ctx.questionid ? 'highlight' : (item.isanswered ? 'answered' : 'unanswered')),
                        size: "small",
                        circle: "",
                        onClick: $event => (_ctx.onQuestionClick(item.questionid))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(index+1), 1)
                        ]),
                        _: 2
                      }, 1032, ["class", "onClick"])
                    ]),
                    _: 2
                  }, 1024))
                }), 256))
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}