import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "drawer-left" }
const _hoisted_3 = { class: "usp-list" }
const _hoisted_4 = { class: "bullet-list" }

export function render(_ctx, _cache) {
  const _component_Header = _resolveComponent("Header")
  const _component_el_image = _resolveComponent("el-image")
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")
  const _component_el_carousel = _resolveComponent("el-carousel")
  const _component_el_col = _resolveComponent("el-col")
  const _component_CircleCheckFilled = _resolveComponent("CircleCheckFilled")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_row = _resolveComponent("el-row")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      windowWidth: _ctx.windowWidth,
      windowHeight: _ctx.windowHeight
    }, null, 8, ["windowWidth", "windowHeight"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.windowWidth >= 982)
        ? (_openBlock(), _createBlock(_component_el_carousel, {
            key: 0,
            height: "60vh",
            "indicator-position": "none",
            arrow: "never",
            interval: 10000
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c1.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c2.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c3.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c4.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c5.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c6.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c7.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c8.webp" })
                ]),
                _: 1
              }),
              _withDirectives(_createElementVNode("div", _hoisted_2, [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "welcome-desktop" }, " Willkommen zur ", -1)),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "welcome-large" }, " MC.health ", -1)),
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "welcome-large" }, " Prüfungsplattform. ", -1)),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "welcome-small" }, " Entdecken Sie unsere digitale Lösung zur Durchführung der schriftlichen Kenntnisüberprüfungen. ", -1)),
                _createElementVNode("div", {
                  class: "button-big",
                  onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onOverviewClick && _ctx.onOverviewClick(...args)))
                }, " Zur Demoversion ")
              ], 512), [
                [_vShow, _ctx.showDrawerLeft && _ctx.windowWidth >= 982]
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.windowWidth < 982)
        ? (_openBlock(), _createBlock(_component_el_carousel, {
            key: 1,
            height: "50vw",
            "indicator-position": "none",
            arrow: "never",
            interval: 10000
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c4.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c2.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c3.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c1.webp" })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              xs: 24,
              sm: 24,
              md: 24,
              lg: 12,
              xl: 12,
              class: "block-left"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("h2", { class: "blue0" }, "Willkommen auf dem Portal der DDG GmbH.", -1),
                _createElementVNode("p", null, "Die Tätigkeit als Heilpraktikerin oder Heilpraktiker erfordert eine Erlaubnis nach dem Heilpraktikergesetz durch ein Gesundheitsamt.", -1),
                _createElementVNode("p", null, "Im Rahmen des verwaltungsseitigen Erlaubnisverfahrens ist es gesetzliche Aufgabe des Gesundheitsamtes festzustellen, ob die erworbenen Fähigkeiten und Fertigkeiten für eine Heilkundeausübung ausreichen. Dazu werden sogenannte Kenntnisüberprüfungen durchgeführt.", -1),
                _createElementVNode("p", null, "Die DDG GmbH bietet eine digitale Plattform zur Durchführung dieser schriftlichen Kenntnisüberprüfung.", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_el_col, {
              xs: 24,
              sm: 24,
              md: 24,
              lg: 12,
              xl: 12,
              class: "block-right"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_el_row, { class: "bullet-item" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, {
                        span: 0.5,
                        class: "bullet-icon"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, { size: "48px" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_CircleCheckFilled)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, {
                        span: 20,
                        class: "bullet-text"
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" Sicherheit ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_row, { class: "bullet-item" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, {
                        span: 0.5,
                        class: "bullet-icon"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, { size: "48px" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_CircleCheckFilled)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, {
                        span: 20,
                        class: "bullet-text"
                      }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode(" Datenschutz ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_row, { class: "bullet-item" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, {
                        span: 0.5,
                        class: "bullet-icon"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, { size: "48px" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_CircleCheckFilled)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, {
                        span: 20,
                        class: "bullet-text"
                      }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode(" Effizienz ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_row, { class: "bullet-item" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, {
                        span: 0.5,
                        class: "bullet-icon"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, { size: "48px" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_CircleCheckFilled)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, {
                        span: 20,
                        class: "bullet-text"
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" Medizinische Kompetenz ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_row, { class: "bullet-item" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, {
                        span: 0.5,
                        class: "bullet-icon"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, { size: "48px" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_CircleCheckFilled)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, {
                        span: 20,
                        class: "bullet-text"
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode(" Nachhaltigkeit ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", {
        class: _normalizeClass('single-block' + (_ctx.windowWidth < 982 ? '-mobile' : '-desktop'))
      }, _cache[11] || (_cache[11] = [
        _createElementVNode("h2", null, "Für Gesundheitsämter", -1),
        _createElementVNode("p", null, "Der aktuelle Förderaufruf zum Digitalen Reifegradmodell bietet Gesundheitsämtern die Chance, die Kenntnisüberprüfungen bei Heilpraktikern von Papier auf ein online-Verfahren umzustellen.", -1),
        _createElementVNode("p", null, "Die digitale Prüfungsplattform MC.health erhöht den Reifegrad in den Dimensionen Bürger*innenzentrierung, Prozessdigitalisierung und Software / Daten / Interoperabilität.", -1),
        _createElementVNode("p", null, " Nutzen Sie die Chance auf Förderung im Rahmen eines Modellprojekts. Wir unterstützen Gesundheitsämter bei der Entwicklung eines Antrags. Gerne machen wir Sie mit dem online Prüfungsverfahren vertraut. ", -1)
      ]), 2),
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 24 }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass('full-block' + (_ctx.windowWidth < 982 ? '-mobile' : '-desktop'))
              }, _cache[12] || (_cache[12] = [
                _createElementVNode("h2", null, "Für Prüflinge", -1),
                _createElementVNode("p", null, "Kenntnisüberprüfungen auf Papier sind derzeit der Standard, wenn Sie eine Erlaubnis zur Tätigkeit als Heilpraktiker*in beantragen.", -1),
                _createElementVNode("p", null, "Moderne Gesundheitsämter sind dabei, die Prüfung digital abzuhalten. Die Plattform MC.health bietet die Möglichkeit, medizinische Kenntnisse im Multiple-Choice- oder Single-Choice-Verfahren abzufragen.", -1)
              ]), 2)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Footer, {
      windowWidth: _ctx.windowWidth,
      windowHeight: _ctx.windowHeight
    }, null, 8, ["windowWidth", "windowHeight"])
  ], 64))
}