import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "test-date" }
const _hoisted_3 = { class: "test-targetgroup" }
const _hoisted_4 = {
  key: 0,
  class: "col-wait"
}
const _hoisted_5 = {
  key: 2,
  class: "col-info"
}

export function render(_ctx, _cache) {
  const _component_Ticket = _resolveComponent("Ticket")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _component_QuestionFilled = _resolveComponent("QuestionFilled")
  const _component_Clock = _resolveComponent("Clock")
  const _component_DocumentChecked = _resolveComponent("DocumentChecked")
  const _component_el_affix = _resolveComponent("el-affix")

  return (_openBlock(), _createBlock(_component_el_affix, { offset: 0 }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              class: _normalizeClass('col-logo' + (_ctx.isRunning ? '-live' : '')),
              span: 1
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Ticket)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_el_col, {
              class: _normalizeClass('col-token' + (_ctx.isRunning ? '-live' : '')),
              span: 4
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.token), 1)
              ]),
              _: 1
            }, 8, ["class"]),
            (_ctx.windowWidth > 920)
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 0,
                  class: "col-test",
                  span: 8
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.testdate), 1),
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.targetgroup.replace(" ", " ")), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (!_ctx.isRunning)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "blink" }, "Bitte warten...", -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.showHelp)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "col-help",
              onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onHelpClick && _ctx.onHelpClick(...args)))
            }, [
              _createVNode(_component_el_icon, { size: "48px" }, {
                default: _withCtx(() => [
                  _createVNode(_component_QuestionFilled)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.isRunning)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_el_row, { class: "row-info" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, {
                        size: "32px",
                        align: "right"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Clock)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, {
                    span: 16,
                    class: _normalizeClass('col-text' + (_ctx.time <= 5 ? ' blink' : ''))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.time > 0 ? _ctx.time : 'letzte') + " " + _toDisplayString('Minute' + (_ctx.time > 1 ? 'n' : '')), 1)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_row, { class: "row-info" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, { size: "32px" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DocumentChecked)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, {
                    span: 16,
                    class: "col-text"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.questions_answered) + " / " + _toDisplayString(_ctx.questions_total) + " Fragen", 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showFinish)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "top-button",
            onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.onFinishClick && _ctx.onFinishClick(...args)))
          }, " Prüfung beenden "))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}