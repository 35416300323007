import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "debug" }

export function render(_ctx, _cache) {
  const _component_el_image = _resolveComponent("el-image")
  const _component_Navigation = _resolveComponent("Navigation")
  const _component_el_affix = _resolveComponent("el-affix")

  return (_openBlock(), _createBlock(_component_el_affix, { offset: 0 }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "logo",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onLogoClick && _ctx.onLogoClick(...args)))
        }, [
          _createVNode(_component_el_image, { src: "/img/ddg-logo-64.png" })
        ]),
        _createElementVNode("div", {
          class: _normalizeClass('logo-line' + ((_ctx.windowWidth < 768) ? '-mobile' : ''))
        }, " Digitale Dienstleistungen im Gesundheitswesen ", 2),
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.windowWidth) + " x " + _toDisplayString(_ctx.windowHeight) + " ", 1),
          _createElementVNode("div", null, _toDisplayString(_ctx.windowWidth >= 1920 ? 'xl' : _ctx.windowWidth >= 1200 ? 'lg' : _ctx.windowWidth >= 992 ? 'md' : _ctx.windowWidth >= 768 ? 'sm' : 'xs'), 1)
        ], 512), [
          [_vShow, _ctx.debug]
        ])
      ]),
      (_ctx.showNavigation)
        ? (_openBlock(), _createBlock(_component_Navigation, {
            key: 0,
            windowWidth: _ctx.windowWidth,
            windowHeight: _ctx.windowHeight
          }, null, 8, ["windowWidth", "windowHeight"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}