import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }

export function render(_ctx, _cache) {
  const _component_Header = _resolveComponent("Header")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      windowWidth: _ctx.windowWidth,
      windowHeight: _ctx.windowHeight,
      showNavigation: false
    }, null, 8, ["windowWidth", "windowHeight"]),
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Produktvorstellung | MC.health | 06.05.2024 - 09:00 Uhr", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("p", null, " Wählen Sie Sich bitte in die untenstehende Webex Konferenz ein, ", -1)),
      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "link" }, [
        _createElementVNode("a", {
          class: "webex",
          href: "https://digitaledienstleistungenimgesundheitswesenddggmbh.my.webex.com/digitaledienstleistungenimgesundheitswesenddggmbh.my/j.php?MTID=md57130ff36955e5814049753c576fbd9",
          target: "_blank"
        }, " Webex Konferenz beitreten ")
      ], -1)),
      _createElementVNode("p", null, [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 8 }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" oder nutzen Sie den QR-Code. ")
              ])),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("img", { src: "/img/webex-qr.png" }, null, -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("p", null, [
        _createTextVNode(" Sie können der Konferenz mit der offiziellen Webex App für Desktop, iOS oder Android beitreten."),
        _createElementVNode("br"),
        _createTextVNode(" Alternativ können Sie auch einfach mit Ihren Webbrowser beitreten."),
        _createElementVNode("br"),
        _createTextVNode(" Für den Browser muss allerdings die Verwendung des Mikrofons und (optional) der Kamera erlaubt werden. ")
      ], -1)),
      _cache[6] || (_cache[6] = _createElementVNode("p", null, [
        _createTextVNode(" Nach der Einwahl, geben Sie sich bitte eine sprechende Teilnehmerbezeichnung."),
        _createElementVNode("br"),
        _createTextVNode(" Danach warten Sie in der Lobby, bis der Gastgeber Sie in das Meeting holt. ")
      ], -1)),
      _cache[7] || (_cache[7] = _createElementVNode("p", null, [
        _createTextVNode(" Bei Problemen mit der Einwahl, wenden Sie sich bitte an "),
        _createElementVNode("a", {
          class: "email",
          href: "mailto:support@ddg.health"
        }, "support@ddg.health"),
        _createTextVNode(". ")
      ], -1)),
      _cache[8] || (_cache[8] = _createElementVNode("p", null, " Wir freuen uns auf Ihren Besuch. ", -1))
    ])
  ], 64))
}