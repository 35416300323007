import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "typoghraphic" }
const _hoisted_3 = { class: "logo" }
const _hoisted_4 = { class: "logo" }
const _hoisted_5 = { class: "logo" }
const _hoisted_6 = { class: "logo" }
const _hoisted_7 = { class: "invert" }
const _hoisted_8 = { class: "logo" }
const _hoisted_9 = { class: "favicon" }

export function render(_ctx, _cache) {
  const _component_Header = _resolveComponent("Header")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_image = _resolveComponent("el-image")
  const _component_el_row = _resolveComponent("el-row")
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")
  const _component_el_carousel = _resolveComponent("el-carousel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      windowWidth: _ctx.windowWidth,
      windowHeight: _ctx.windowHeight,
      showNavigation: false
    }, null, 8, ["windowWidth", "windowHeight"]),
    _createElementVNode("div", _hoisted_1, [
      _cache[26] || (_cache[26] = _createElementVNode("h1", null, "Corporate Identity & Brand Design", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_row, { class: "table" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 10,
              class: "key",
              align: "right"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" COMPANY LOGO ")
              ])),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 12,
              class: "value"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_el_image, { src: "/img/ddg-logo-black.png" }),
                  _cache[1] || (_cache[1] = _createElementVNode("label", null, "brand / full / cover", -1))
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1)),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_el_image, { src: "/img/ddg-logo-64.png" }),
                  _cache[2] || (_cache[2] = _createElementVNode("label", null, "icon / small / navigation", -1))
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1)),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_el_image, { src: "/img/ddg-logo-64-c.png" }),
                  _cache[3] || (_cache[3] = _createElementVNode("label", null, "icon / small / colored", -1))
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("hr", null, null, -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_el_image, { src: "/img/ddg-logo-64-w.png" })
                  ]),
                  _cache[4] || (_cache[4] = _createElementVNode("label", null, "icon / mini / inverted", -1))
                ]),
                _cache[9] || (_cache[9] = _createElementVNode("hr", null, null, -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_el_image, { src: "/img/favicon.ico" })
                  ]),
                  _cache[5] || (_cache[5] = _createElementVNode("label", null, "favicon", -1))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "table" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 10,
              class: "key",
              align: "right"
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode(" TYPOGRAPHY ")
              ])),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 12,
              class: "value"
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createElementVNode("div", { class: "font-face" }, "Aileron", -1),
                _createElementVNode("div", { class: "font-regular" }, "Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll MM Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz", -1),
                _createElementVNode("div", { class: "font-regular" }, "0 1 2 3 4 5 6 7 8 9", -1),
                _createElementVNode("hr", null, null, -1),
                _createElementVNode("div", { class: "font-heavy" }, ": Heavy", -1),
                _createElementVNode("div", { class: "font-normal" }, ": Regular", -1),
                _createElementVNode("div", { class: "font-light" }, ": Light", -1),
                _createElementVNode("div", { class: "font-ultralight" }, ": Ultra Light", -1),
                _createElementVNode("hr", null, null, -1),
                _createElementVNode("div", { class: "font-normal-16" }, "Die Tätigkeit als Heilpraktikerin oder Heilpraktiker erfordert eine Erlaubnis nach dem Heilpraktikergesetz durch ein Gesundheitsamt.", -1),
                _createElementVNode("hr", null, null, -1),
                _createElementVNode("div", { class: "font-normal-16" }, [
                  _createElementVNode("ul", null, [
                    _createElementVNode("li", null, "Sicherheit"),
                    _createElementVNode("li", null, "Datenschutz"),
                    _createElementVNode("li", null, "Effizienz"),
                    _createElementVNode("li", null, "medizinische Kompetenz"),
                    _createElementVNode("li", null, "Nachhaltigkeit")
                  ])
                ], -1),
                _createElementVNode("hr", null, null, -1),
                _createElementVNode("div", { class: "font-normal-16" }, [
                  _createElementVNode("h1", null, "H1")
                ], -1),
                _createElementVNode("div", { class: "font-normal-16" }, [
                  _createElementVNode("h2", null, "H2")
                ], -1),
                _createElementVNode("div", { class: "font-normal-16" }, [
                  _createElementVNode("h3", null, "H3")
                ], -1),
                _createElementVNode("div", { class: "font-normal-16" }, [
                  _createElementVNode("h4", null, "H4")
                ], -1),
                _createElementVNode("div", { class: "font-normal-16" }, [
                  _createElementVNode("h5", null, "H5")
                ], -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "table" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 10,
              class: "key",
              align: "right"
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode(" COLORS ")
              ])),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 12,
              class: "value"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 5,
                      class: "color"
                    }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createElementVNode("div", { class: "color-base" }, "#DDDDDD", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_col, { span: 15 }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createElementVNode("div", { class: "color-base" }, "BASE", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 5,
                      class: "color"
                    }, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createElementVNode("div", { class: "color-layer-0" }, "#1A4B5A", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_col, { span: 15 }, {
                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                        _createElementVNode("div", { class: "color-layer-0" }, "LAYER 0", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 5,
                      class: "color"
                    }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createElementVNode("div", { class: "color-layer-1" }, "#1F6074", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_col, { span: 15 }, {
                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createElementVNode("div", { class: "color-layer-1" }, "LAYER 1", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 5,
                      class: "color"
                    }, {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createElementVNode("div", { class: "color-layer-2" }, "#3E8A9A", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_col, { span: 15 }, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createElementVNode("div", { class: "color-layer-2" }, "LAYER 2", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 5,
                      class: "color"
                    }, {
                      default: _withCtx(() => _cache[21] || (_cache[21] = [
                        _createElementVNode("div", { class: "color-layer-3" }, "#89BFC9", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_col, { span: 15 }, {
                      default: _withCtx(() => _cache[22] || (_cache[22] = [
                        _createElementVNode("div", { class: "color-layer-3" }, "LAYER 3", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 5,
                      class: "color"
                    }, {
                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                        _createElementVNode("div", { class: "color-layer-h" }, "#E5CA6F", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_col, { span: 15 }, {
                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                        _createElementVNode("div", { class: "color-layer-h" }, "HIGHLIGHT", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "table" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 10,
              class: "key",
              align: "right"
            }, {
              default: _withCtx(() => _cache[25] || (_cache[25] = [
                _createTextVNode(" KEY GRAPHICS ")
              ])),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 12,
              class: "value"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_carousel, {
                  height: "550px",
                  "indicator-position": "none",
                  arrow: "never",
                  interval: 3000,
                  "pause-on-hover": false
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_carousel_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_image, { src: "/img/c1.webp" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_carousel_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_image, { src: "/img/c2.webp" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_carousel_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_image, { src: "/img/c3.webp" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_carousel_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_image, { src: "/img/c4.webp" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_carousel_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_image, { src: "/img/c5.webp" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_carousel_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_image, { src: "/img/c6.webp" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_carousel_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_image, { src: "/img/c7.webp" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_carousel_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_image, { src: "/img/c8.webp" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}