import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_Header = _resolveComponent("Header")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      windowWidth: _ctx.windowWidth,
      windowHeight: _ctx.windowHeight
    }, null, 8, ["windowWidth", "windowHeight"]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "content" }, null, -1)),
    _createVNode(_component_Footer)
  ], 64))
}