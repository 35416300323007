import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "help-text"
}
const _hoisted_2 = { class: "question-buttons" }
const _hoisted_3 = { class: "button-explanation" }

export function render(_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")

  return (_ctx.helpIndex == 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", null, [
          _createTextVNode("Die Prüfungsdauer beträgt " + _toDisplayString(_ctx.timeLimit) + " Minuten.", 1),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" Es sind insgesamt " + _toDisplayString(_ctx.questionCount) + " Fragen zu beantworten.", 1)
        ]),
        _createElementVNode("p", null, "Zu jeder Frage gibt es " + _toDisplayString(_ctx.testMode == 0 ? 'eine korrekte Antwort' : 'mindestens eine korrekte Antwort, aber es können auch mehrere Antworten korrekt sein') + ".", 1),
        _cache[14] || (_cache[14] = _createElementVNode("p", null, [
          _createTextVNode("Über die Fragenleiste können Sie zur gewünschten Frage springen."),
          _createElementVNode("br"),
          _createTextVNode(" Die Farbe hinter der Fragennummer zeigt dabei den aktuellen Status an.")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            class: "answered",
            size: "small",
            circle: ""
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("1")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            class: "answered",
            size: "small",
            circle: ""
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("2")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            class: "answered",
            size: "small",
            circle: ""
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("3")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            class: "highlight",
            size: "small",
            circle: ""
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("4")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            class: "unanswered",
            size: "small",
            circle: ""
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("5")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            class: "unanswered",
            size: "small",
            circle: ""
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("6")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            class: "unanswered",
            size: "small",
            circle: ""
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("7")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", null, [
            _createVNode(_component_el_button, {
              class: "answered",
              size: "large",
              circle: ""
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("1")
              ])),
              _: 1
            }),
            _cache[9] || (_cache[9] = _createTextVNode(" = beantwortete Frage "))
          ]),
          _createElementVNode("p", null, [
            _createVNode(_component_el_button, {
              class: "highlight",
              size: "large",
              circle: ""
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("4")
              ])),
              _: 1
            }),
            _cache[11] || (_cache[11] = _createTextVNode(" = aktuell gewählte Frage "))
          ]),
          _createElementVNode("p", null, [
            _createVNode(_component_el_button, {
              class: "unanswered",
              size: "large",
              circle: ""
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("6")
              ])),
              _: 1
            }),
            _cache[13] || (_cache[13] = _createTextVNode(" = unbeantwortete Frage "))
          ])
        ]),
        _cache[15] || (_cache[15] = _createElementVNode("p", null, "Sie können die Prüfung auch vorzeitig über den entsprechenden Button in der Kopfzeile beenden.", -1))
      ]))
    : _createCommentVNode("", true)
}