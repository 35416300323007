import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = {
  key: 0,
  class: "version"
}

export function render(_ctx, _cache) {
  const _component_el_col = _resolveComponent("el-col")
  const _component_Plus = _resolveComponent("Plus")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[11] || (_cache[11] = _createElementVNode("p", { class: "title" }, "Digitale Dienstleistungen im Gesundheitswesen (DDG) GmbH", -1)),
    (_ctx.windowWidth >= 982)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "V. " + _toDisplayString(_ctx.version), 1))
      : _createCommentVNode("", true),
    (_ctx.windowWidth < 982)
      ? (_openBlock(), _createBlock(_component_el_row, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 24,
              class: "mobile"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("p", null, "Geschäftsführung: Dr. Christian Herzmann", -1),
                _createElementVNode("p", null, "Breslauer Str. 27", -1),
                _createElementVNode("p", null, "23843 Bad Oldesloe", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 24,
              class: "mobile"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("p", null, "Verantwortlich im Sinne des Presserechts", -1),
                _createElementVNode("p", null, "PD Dr. med. Christian Herzmann", -1),
                _createElementVNode("p", null, "HRB 24292 HL", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 24,
              class: "mobile"
            }, {
              default: _withCtx(() => [
                _cache[4] || (_cache[4] = _createElementVNode("p", null, [
                  _createTextVNode("Kontakt: info "),
                  _createElementVNode("img", {
                    src: "/img/at.png",
                    alt: "@"
                  }),
                  _createTextVNode(" ddg.health")
                ], -1)),
                _createElementVNode("p", null, [
                  _cache[2] || (_cache[2] = _createTextVNode("Telefon: ")),
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Plus)
                    ]),
                    _: 1
                  }),
                  _cache[3] || (_cache[3] = _createTextVNode("49 4531 8971347"))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_el_row, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 6,
              class: "desktop"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("p", null, "Geschäftsführung: Dr. Christian Herzmann", -1),
                _createElementVNode("p", null, "Breslauer Str. 27", -1),
                _createElementVNode("p", null, "23843 Bad Oldesloe", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 6,
              class: "desktop"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("p", null, "Verantwortlich im Sinne des Presserechts:", -1),
                _createElementVNode("p", null, "PD Dr. med. Christian Herzmann", -1),
                _createElementVNode("p", null, "HRB 24292 Lübeck | 30/294/02904", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 6,
              class: "desktop"
            }, {
              default: _withCtx(() => [
                _cache[9] || (_cache[9] = _createElementVNode("p", null, "Kontakt:", -1)),
                _cache[10] || (_cache[10] = _createElementVNode("p", null, [
                  _createTextVNode("info "),
                  _createElementVNode("img", {
                    src: "/img/at.png",
                    alt: "@"
                  }),
                  _createTextVNode(" ddg.health")
                ], -1)),
                _createElementVNode("p", null, [
                  _cache[7] || (_cache[7] = _createTextVNode("Telefon: ")),
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Plus)
                    ]),
                    _: 1
                  }),
                  _cache[8] || (_cache[8] = _createTextVNode("49 4531 8971347"))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
  ]))
}