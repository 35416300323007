import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "nav-desktop"
}
const _hoisted_2 = {
  key: 1,
  class: "nav-mobile"
}

export function render(_ctx, _cache) {
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _component_el_drawer = _resolveComponent("el-drawer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.windowWidth >= 1200)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_row, { class: "nav-list" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 5 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass('menu-item' + (_ctx.navIndex == _ctx.NavigationIndex.HOME ? '-active' : '')),
                    onClick: _cache[0] || (_cache[0] = $event => (_ctx.onLinkClick(_ctx.NavigationIndex.HOME)))
                  }, "Startseite", 2)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 5 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass('menu-item' + (_ctx.navIndex == _ctx.NavigationIndex.FOR_CANDIDATES ? '-active' : '')),
                    onClick: _cache[1] || (_cache[1] = $event => (_ctx.onLinkClick(_ctx.NavigationIndex.FOR_CANDIDATES)))
                  }, "Prüflinge", 2)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 8 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass('menu-item' + (_ctx.navIndex == _ctx.NavigationIndex.FOR_HEALTH_DEPT ? '-active' : '')),
                    onClick: _cache[2] || (_cache[2] = $event => (_ctx.onLinkClick(_ctx.NavigationIndex.FOR_HEALTH_DEPT)))
                  }, "Gesundheitsämter", 2)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 4 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass('menu-item' + (_ctx.navIndex == _ctx.NavigationIndex.IMPRINT ? '-active' : '')),
                    onClick: _cache[3] || (_cache[3] = $event => (_ctx.onLinkClick(_ctx.NavigationIndex.IMPRINT)))
                  }, "Rechtliches", 2)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, { class: "nav-list" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 14 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass('menu-item' + (_ctx.navIndex == _ctx.NavigationIndex.DEMOVERSION ? '-active' : '')),
                    onClick: _cache[4] || (_cache[4] = $event => (_ctx.onLinkClick(_ctx.NavigationIndex.DEMOVERSION)))
                  }, "Demoversion für Prüflinge", 2)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 2 }),
              _createVNode(_component_el_col, { span: 2 }),
              _createVNode(_component_el_col, { span: 2 })
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "burger-button",
            onClick: _cache[5] || (_cache[5] = (...args) => (_ctx.onMenuClick && _ctx.onMenuClick(...args)))
          }, _cache[11] || (_cache[11] = [
            _createElementVNode("div", { class: "bar" }, null, -1),
            _createElementVNode("div", { class: "bar" }, null, -1),
            _createElementVNode("div", { class: "bar" }, null, -1)
          ]))
        ])),
    _createVNode(_component_el_drawer, {
      modelValue: _ctx.showMenu,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.showMenu) = $event)),
      withHeader: true,
      direction: "rtl",
      "attach-to-body": true,
      size: (_ctx.windowWidth < 768 ? '100%' : '60%')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "menu-item-mobile",
          onClick: _cache[6] || (_cache[6] = $event => (_ctx.onLinkClick(_ctx.NavigationIndex.HOME)))
        }, "Startseite"),
        _createElementVNode("div", {
          class: "menu-item-mobile",
          onClick: _cache[7] || (_cache[7] = $event => (_ctx.onLinkClick(_ctx.NavigationIndex.FOR_CANDIDATES)))
        }, "Prüflinge"),
        _createElementVNode("div", {
          class: "menu-item-mobile",
          onClick: _cache[8] || (_cache[8] = $event => (_ctx.onLinkClick(_ctx.NavigationIndex.FOR_HEALTH_DEPT)))
        }, "Gesundheitsämter"),
        _createElementVNode("div", {
          class: "menu-item-mobile",
          onClick: _cache[9] || (_cache[9] = $event => (_ctx.onLinkClick(_ctx.NavigationIndex.IMPRINT)))
        }, "Rechtliches")
      ]),
      _: 1
    }, 8, ["modelValue", "size"])
  ], 64))
}